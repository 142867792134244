import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqsPanel from "../components/FaqsPanel"

const SecondPage = () => (
  <Layout>
    <SEO title="Pregutas frecuentes covid test" />
    <FaqsPanel />
  </Layout>
)

export default SecondPage
